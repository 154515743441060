import { stars } from '../../images';
import { Mixpanel } from '../../mixpanel';
import React from 'react';
import styles from './IntroView.module.scss';

const IntroView: React.FunctionComponent = () => {
	// const screenSize = useScreenSize();

	// const isMobileScreen = ['mobile', 'tablet'].includes(screenSize);
	return (
		<>
			<div className={`${styles.page} ${styles.pageOne}`}>
				<div className={styles.emojiContainer}>
					<img
						className={styles.mascot}
						style={{ marginRight: '60px' }}
						width={500}
						src={stars}
						alt=''
					/>
				</div>
				<div className={styles.textContainer}>
					<div className={styles.pageTitle}>
						Welcome to CollabCare by Collab.Land
						<br />
					</div>
					<div className={styles.pagSecondaryTitle}>
						{' '}
						Let's Take This Journey Together!
					</div>
					<br/>
					<br/>
					<p className={styles.pageDesc}>
						GM GM! In the bustling world of web3, we believe it's more than just tech and transactions—it's about
						friendship, trust, and delivering a little magic. With that spirit, we're super excited to introduce
						CollabCare, our cozy corner of premium support made especially for awesome communities like you.
					</p>
					<p className={styles.pageDesc}>
						<b>🔗 Direct Connection:</b> Our premium channel is not just about getting answers; it’s about building a relationship. Here, you’ll experience direct, immediate, and uniquely personalized attention for all your Collab.Land queries. No bots, no automated responses - just real-time solutions tailored to you. You're not just another project; you're a part of our inner circle.
					</p>
					<p className={styles.pageDesc}>
						<b>⚡️ Skip the Support Line:</b> Your time is valuable. Why wait? As a premium member, the typical support line isn’t in your vocabulary. Get prompt answers, actionable solutions, and exceptional speed in addressing all your community needs. Experience support as it should be – swift and efficient.
					</p>
					<p className={styles.pageDesc}>
						<b>	👥 Invite Your Team:</b> Collaboration is the heart of innovation. Extend the perks of CollabCare to up to 10 of your team members. Engage, collaborate, and benefit as a cohesive unit.
					</p>
					<p className={styles.pageDesc}>
						CollabCare is now available for any project using Collab.Land. <b>But spots are limited</b> in order to preserve the integrity and quality of the service.
					</p>
					<div className={styles.schedule}>
						To get started on our CollabCare journey, head over to the Collab.Land Command Center today
						<a
							target='_blank'
							rel='noopener noreferrer'
							className={styles.introLink}
							href='https://cc.collab.land/'
							onClick={() => {
								Mixpanel.track('care-collabland command-center-btn', {
									button: 'command-center'
								});
							}}
						>
							Command Center
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

export default IntroView;
